<template>
  <Workspace>
    <template v-slot:top-bar>
      <TopBar>
        <template v-slot:title>Document</template>
      </TopBar>
    </template>
    <template v-slot:content>

    </template>

  </Workspace>
</template>

<script>
import Workspace from "../layouts/Workspace";
import TopBar from "../layouts/workspace/TopBar";

export default {
  name: "Document",
  components: {Workspace, TopBar}
}
</script>

<style scoped>

</style>